import React from "react";
import moment from "moment";
import "./outsiderHeader.less";
const AuthComponent = Loader.loadBusinessComponent("AuthComponent");
const AlarmDetailHeader = Loader.loadBusinessComponent(
  "MonitorHistoryAlarm",
  "AlarmDetailHeader"
);

@Decorator.businessProvider("menu")
class OutsiderHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkType: false
    };
  }
  componentDidMount() {
    const { menu } = this.props;
    const action = menu.getInfoByName("outsiderLibraryView");
    if (action) {
      this.setState({
        checkType: true
      });
    }
  }

  goLibDetail = id => {
    if (this.state.checkType) {
      this.props.goLibDetail(id);
    }
  };

  render() {
    let {
      data = {},
      handleText,
      saveText,
      operationDetail,
      handleOpenModal,
      libList
    } = this.props;
    const sceneCode = BaseStore.user.appInfo.sceneCode;
    const monitorLabel = Dict.map.monitorLabel[sceneCode];
    return (
      <AlarmDetailHeader
        saveText={saveText}
        handleText={handleText}
        operationDetail={operationDetail}
        handleOpenModal={handleOpenModal}
        data={data}
        handleAuthName='outsiderHandle'
        rightCenterP={
          <>
            <div className="center-message outsider-center-message">
              <p className="right-center-p" title={data.taskName}>
                <span className="right-center-span "> 所在布控任务：</span>
                <span className="task-name">{data.taskName || "-"}</span>
              </p>
              <p className="right-center-p" title={data.deviceName}>
                <span className="right-center-span">告警设备名称：</span>
                {data.deviceName || "-"}
              </p>
              <p className="right-center-p" title={data.address}>
                <span className="right-center-span">告警设备地址：</span>
                {data.address || "-"}
              </p>
              <p className="right-center-p">
                <span className="right-center-span">告警时间：</span>
                {(data &&
                  moment(+data.captureTime).format("YYYY.MM.DD HH:mm:ss")) ||
                  "-"}
              </p>
            </div>
            <div className="center-lib">
              <div className="center-lib-title">{monitorLabel.outsider.libLabel}：</div>
              <div className="center-lib-box">
                {libList.length > 0
                  ? libList.map(v => {
                      return (
                        <div className="btn-box" key={v.id}>
                          <AuthComponent
                            actionName="outsiderLibraryView"
                            noAuthContent={
                              <span className="btn-box-span">{v.name}</span>
                            }
                          >
                            <span
                              className="btn-box-span"
                              title={v.name}
                              onClick={() => this.goLibDetail(v.id)}
                            >
                              {v.name}
                            </span>
                          </AuthComponent>
                        </div>
                      );
                    })
                  : "-"}
              </div>
            </div>
          </>
        }
      />
    );
  }
}

export default OutsiderHeader;
