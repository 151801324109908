import React from 'react';
import { message } from 'antd';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import OutsiderHeader from './component/outsiderHeader';

import './index.less';

const Loading = Loader.Loading;
const NoData = Loader.loadBaseComponent('NoData');
const ConfirmComponent = Loader.loadBaseComponent('ConfirmComponent');
const ImageMovieMap = Loader.loadBusinessComponent('ImageMovieMap');
const PageDetails = Loader.loadBusinessComponent('PageDetails');

// 非法入侵告警详情

@withRouter
@Decorator.withEntryLog()
@Decorator.businessProvider('tab', 'user', 'device')
@observer
class OutsiderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {},
      oldData: undefined,
      handleVisible: false,
      operationDetail: '',
      libList: [],
      detailList: [], // 外部告警列表
      detailpoint: [],
      isRealAlarm: false,
      libType: '',
      searchData: {},
      alarmInfoList: [],
      points: [], // 排序点位
      checkShow: false, // 控制底部列表单选按钮显示隐藏
    };
    /*
		libType
		详情类型
			重点人员布控历史告警详情  1
			非法入侵告警详情         2
			魅影告警								3
			专网套件告警详情         4
	*/
  }
  componentWillMount() {
    let { history } = this.props;
    const { id } = Utils.queryFormat(history.location.search);
    window.LM_DB.get('parameter', id)
      .then(data => {
        this.setState({
          loading: true,
          detailList: data.list,
          libType: data.libType,
          searchData: data.searchData,
          isRealAlarm: data.isRealAlarm
        });
        this.getDetail(id).then(() => {
          this.getAlarmList();
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    });
  }
 

  getDetail = id => {
    const { libType, isRealAlarm = false } = this.state;
    return Service.alarmResult.alarmResults({ id, libType, isRealAlarm }).then(res => {
      let data = res.data;
      this.setState({
        loading: false,
        data,
        operationDetail: data.operationDetail
      });
      return data
    });
  };

  // 跳转到人员库管理 若没有管理权限不给予跳转
  goLibDetail = libId => {
    let { tab, user } = this.props;
    Service.monitorLib.queryMonitorLibDetail(libId).then(res => {
      let managers = res.data.managers;
      let userId = user.userInfo.id;
      if (managers.find(v => v.id === userId)) {
        tab.goPage({
          moduleName: 'outsiderLibraryView', 
          state: { libId } 
        });
      }
    });
  };

  handleText = e => {
    const value = e.target.value;
    if (value.length > 200) {
      message.info('最大长度不超过200');
      return;
    }
    this.setState({
      operationDetail: value
    });
  };

  handleOpenModal = type => {
    this.setState({
      type,
      handleVisible: true
    });
  };

  onModalCancel = () => {
    this.setState({
      handleVisible: false
    });
  };
  //保存备注
  saveText =() => {
    let { data, operationDetail, libType, isRealAlarm } = this.state;
    if(operationDetail === data.operationDetail) {
      return
    }
    Service.alarmResult.handleAlarmResult({
      id: data.id,
      operationDetail
    },
    {
      libType,
      isRealAlarm
    }).then(res => {
      message.success('保存备注成功')
      this.setState({
        data: res.data,
      })
    }).catch(err => message.error(err.data.message))
  }

  // 处理有效无效
  handleOk = () => {
    let {
      data,
      type,
      detailList,
      libType,
      isRealAlarm,
      oldData
    } = this.state;
    Service.alarmResult.handleAlarmResult(
      {
        id: data.id,
        isEffective: type
      },
      {
        libType,
        isRealAlarm
      }
    ).then(res => {
      this.setState({
        data: res.data,
        handleVisible: false,
      });
      return res;
    }).then(res => {
      message.info('设置告警状态成功');
      SocketEmitter.emit(SocketEmitter.eventName.resolveAlarm, res.data);
      let nextDetail = undefined;
      if (detailList && detailList.length > 0) {
        let chose = {};
        if (oldData) {
          chose = detailList.find(v => v.id == oldData.id);
        } else {
          chose = detailList.find(v => v.id == data.id);
        }
        let number = detailList.indexOf(chose);
        if (number < detailList.length && number > -1) {
          nextDetail = detailList[number + 1];
        }
      }
      if (nextDetail) {
        this.changeDetailView(nextDetail.id);
      } else {
        this.getAlarmList();
      }
    });
  };
  //获取下方列表
  getAlarmList = () => {
    let { data } = this.state
    Service.monitorTask.queryMonitorTaskDetail(data.taskId)//获取任务详情得到合规人员库
    .then(res => {
      this.setState({
        libList: res.data.libs
      });
    });
  };

  handleChangeList = id => {
    this.setState(
      {
        oldData: this.state.data
      },
      () => {
        this.getDetail(id).then(() => {
          this.getAlarmList();
        });
      }
    );
  };

  // 更新地址栏url
  updatePage = (id) => {
    const { tab } = this.props;
    const { searchData, isRealAlarm, libType } = this.state;
    window.LM_DB.add('parameter', {
      id: id.toString(),
      libType,
      isRealAlarm,
      searchData
    }).then(() => {
      tab.goPage({
        moduleName: 'outsiderDetail',
        data: { id },
        action: 'replace',
        isUpdate: true
      })
    });
  }

  changeDetailView = id => {
    this.updatePage(id);
    this.getDetail(id).then(data => {
      Service.alarmResult.queryAlarmResults({ monitorPersonPictureId: data.infoId })
        .then(res => {
          if (res.data.list.length > 0) {
            res.data.list.map(v => {
              if(v.isHandle == 0 || v.isEffective !== 0) {
                v.checked = 1;
              } else {
                v.checked = 0;
              }
            });
          }
          this.setState({
            alarmInfoList: res.alarmInfoList,
          });
        });
    });
  };
  renderContent() {
    let {
      data = {},
      libList,
      loading,
      detailList = [],
      operationDetail
    } = this.state;
    if (loading) {
      return null;
    }
    if (Object.keys(data).length === 0) {
      return <NoData />;
    }
    let dataIndex = detailList.findIndex(v => v.id === data.id),
      preData = undefined,
      nextData = undefined;
    if (dataIndex > 0) {
      preData = detailList[dataIndex - 1];
      nextData = detailList[dataIndex + 1];
    }
    if (dataIndex === 0 && detailList.length > 1) {
      nextData = detailList[dataIndex + 1];
    }
    return (
      <>
        <OutsiderHeader
          data={data}
          libList={libList}
          handleText={this.handleText}
          saveText={this.saveText}
          handleOpenModal={this.handleOpenModal}
          operationDetail={operationDetail}
          goLibDetail={this.goLibDetail}
        />
        <div className="detail_imm">
          {preData ? (
            <PageDetails
              pageType="pre"
              imgUrl={preData.faceUrl}
              id={preData.id}
              onChange={this.changeDetailView}
              classNameNode='detail-text'
            />
          ) : (
            <div className="null" />
          )}
          <ImageMovieMap type='personAlarm' data={data} key={data.id} maptype={false} />
          {nextData ? (
            <PageDetails
              pageType="next"
              imgUrl={nextData.faceUrl}
              id={nextData.id}
              onChange={this.changeDetailView}
              classNameNode='detail-text'
            />
          ) : (
            <div className="null" />
          )}
        </div>
      </>
    );
  }
  render() {
    let { handleVisible, type, loading } = this.state;

    return (
      <div className="outsider-detail">
        <Loading loading={loading}>{this.renderContent()}</Loading>
        <ConfirmComponent
          title={type == 1 ? '有效告警确认' : '无效告警确认'}
          visible={handleVisible}
          onCancel={this.onModalCancel}
          onOk={this.handleOk}
          width={320}
          icon={type == 1 ? 'icon-S_Photo_ThumbEffective' : 'icon-S_Photo_ThumbInvalid'}
          children={
            <div>点击“确定”将其标注为{type == 1 ? '有' : '无'}效告警？</div>
          }
        />
      </div>
    );
  }
}

export default OutsiderDetail;
